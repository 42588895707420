@import url("https://fonts.googleapis.com/css?family=Roboto:500,400");
.md-login {
  top: 60px;
  width: 100%;
  min-width: 100vw;
  height: auto;
  min-height: 550px;
  position: relative;
  overflow: hidden;
  margin: 0px;
  -webkit-font-md-loginoothing: antialiased;
  -moz-osx-font-md-loginoothing: grayscale;
  background-color: rgba(255, 255, 255, 1);
}

a {
  text-decoration: none;
}

div {
  -webkit-text-size-adjust: none;
}

.anima-full-width-a {
  width: 100%;
}

.anima-full-height-a {
  height: 100%;
}

div {
  -webkit-text-size-adjust: none;
}

.login-pageTitle {
  position: absolute;
  top: 15px;
  width: 100vw;
  text-align: center;
}

.md-login .back {
  top: 10px;
  left: 5vw;
  height: 22px;
  width: 19px;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.md-login .iniciarsess343o {
  height: auto;
  left: 103px;
  font-family: "Roboto", Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 25px;
  color: rgba(71, 73, 79, 1);
  text-align: center;
}

.md-login .esquecesteapalavra {
  background-color: rgba(255, 255, 255, 0);
  top: 275px;
  height: auto;
  position: absolute;
  margin: 0;
  right: 37.5vw;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-style: normal;
  font-size: 16px;
  color: rgba(4, 67, 117, 1);
  text-align: justify;
  line-height: 21px;
}

.md-login .n343otenscontaregi {
  top: 305px;
  height: auto;
  position: absolute;
  right: 37.5vw;
  font-size: 16px;
  color: rgba(4, 67, 117, 1);
  text-align: justify;
  line-height: 21px;
}

/* Usado para email */

.md-login .textfield {
  top: 70px;
  position: absolute;
}

/* Usado para email */

.md-login .textfield .email {
  width: 25vw;
  position: absolute;
  left: 37.5vw;
}

/* Usado para password */

.md-login .textfield1 {
  top: 130px;
  position: absolute;
}

/* Usado para password */

.md-login .textfield1 .password {
  width: 25vw;
  position: absolute;
  left: 37.5vw;
}

.md-login .button {
  text-align: center;
  top: 210px;
  height: 42px;
  width: 100vw;
  position: absolute;
}

@media screen and (max-width: 767px) {
  .xs-login {
    display: block;
  }
  .sm-login {
    display: none;
  }
  .md-login {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1365px) {
  .xs-login {
    display: none;
  }
  .sm-login {
    display: block;
  }
  .md-login {
    display: none;
  }
}

@media screen and (min-width: 1366px) {
  .xs-login {
    display: none;
  }
  .sm-login {
    display: none;
  }
  .md-login {
    display: block;
  }
}

.colsFBButton {
  text-align: right;
  margin-top: 20px;
}

.colsGButton {
  text-align: left;
  margin-top: 20px;
  display: flex;
  justify-content: left;
}

@media screen and (max-width:768px) {
  .colsFBButton {
    text-align: center;
  }
  .colsGButton {
    text-align: center;
    justify-content: center;
  }
}

.facebookLoginButton {
  border-radius: 25px;
  padding: 15px 15px;
  background-color: white;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .25);
  border: 1px solid #3B5998;
  color: #3B5998;
  font-weight: 500;
}

.facebookLoginButton svg {
  margin-bottom: 3px;
  padding-right: 5px;
}

.facebookLoginButton:hover {
  background-color: #3B5998;
  color: white;
}

.googleLoginButton {
  border-radius: 25px;
  border: 0.5px solid rgba(0, 0, 0, .25);
  padding: 15px 15px;
  background-color: white;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .25);
  color: #0a0c13b6;
  font-weight: 500;
  display: flex;
  align-items: center;
  opacity: 1;
}

.googleLoginButton:disabled {
  opacity: 0.5;
}

.googleLoginButton:hover {
  background-color: #2020201a;
  color: #0a0c13b6;
}

.googleLoginButton svg {
  font-size: 20px;
  margin-right: 5px;
}

.textFieldFontStyle, .textFieldFontStyle input, .textFieldFontStyle label {
  font-family: "Montserrat", sans-serif!important;
  font-weight: 500;
}