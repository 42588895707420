.size {
  font-size: 12px;
}

.businessconsulting:hover {
  transform: scale(1.1);
}

.digupulse:hover {
  transform: scale(1.1);
}

.solfut:hover {
  transform: scale(1.1);
}

.wonderlives:hover {
  transform: scale(1.1);
}

.nascidosparatriunfar:hover {
  transform: scale(1.1);
}